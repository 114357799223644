<template>
  <div>
    <div style="background:#03063c">
      <el-image width="1920" height="568" :src="bannerUrl"></el-image>
    </div>
    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1320px; margin:0 auto; text-align:left">
        <ul>
          <router-link :to="{ path: '/components/web/itc/case01' }">
            <li class="tabStyleLi">
              <div class="tabStyle" style="background:#3246F4"></div>
              <div
                class="tabStyle2"
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>01</div>
                <div>{{ $t('Nav.quanfangweiitc') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/itc/case02' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>02</div>
                <div>{{ $t('Nav.quanqiuopo') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/itc/case03' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>03</div>
                <div>{{ $t('Nav.anlifenxiang') }}</div>
              </div>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133">{{
            $t('Nav.home')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/components/web/itc/index' }">
          <span style="color:#303133">{{
            $t('Nav.itc')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/components/web/itc/case01' }"
          ><span style="color:#303133">
            {{ $t('Nav.quanfangweiitc') }}</span
          ></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133">
            {{ $t('Nav.itccase03_1') }}</span
          ></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <div
      style="width:1200px; margin:0 auto; height:1500px;background:#F6F7F8; padding-top:10px"
    >
      <div style="margin-top:20px">
        <div
          style="text-align:center; height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 24px;"
        >
          {{ $t('Nav.itccase03_1') }}
        </div>
        <div
          style="text-indent:30px; margin-top:30px;text-align:left;line-height:28px; "
        >
          {{ $t('Nav.itccase03_2') }}
        </div>
        <div
          style="text-indent:30px; margin-top:30px;text-align:left;line-height:28px; "
        >
          {{ $t('Nav.itccase03_3') }}
        </div>
      </div>
      <div style="margin-top:20px;">
        <el-image :src="qygsImg1" style="width:734px; height:397px;"></el-image>
      </div>
      <div
        style="text-align:left; height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 24px;"
      >
        {{ $t('Nav.itccase03_4') }}
      </div>
      <div
        style="text-indent:30px; margin-top:20px;text-align:left;line-height:28px; "
      >
        {{ $t('Nav.itccase03_5') }}
      </div>
      <div
        style="text-indent:30px; margin-top:10px;text-align:left;line-height:28px; "
      >
        {{ $t('Nav.itccase03_6') }}
      </div>
      <div
        style="text-indent:30px; margin-top:10px;text-align:left;line-height:28px; "
      >
        {{ $t('Nav.itccase03_7') }}
      </div>
      <div
        style="text-indent:30px; margin-top:10px;text-align:left;line-height:28px; "
      >
        {{ $t('Nav.itccase03_8') }}
      </div>
      <div
        style="text-indent:30px; margin-top:10px;text-align:left;line-height:28px; "
      >
        {{ $t('Nav.itccase03_9') }}
      </div>
      <div
        style="text-align:left; margin-top:30px;height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 24px;"
      >
        {{ $t('Nav.itccase03_10') }}
      </div>
      <div style="margin-top:20px;">
        <el-image :src="qygsImg2" style=" height:512px;"></el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'case01',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/44.jpg'),
      corporateVision1: require('../../../assets/jifang.png'),
      corporateVision2: require('../../../assets/aboutus/corporateVision-img2.png'),
      corporateVision3: require('../../../assets/aboutus/corporateVision-img3.png'),
      qygsImg1: require('../../../assets/itc/case3/1.png'),
      qygsImg2: require('../../../assets/itc/case3/2.png'),
    }
  },
  methods: {},
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 170px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
</style>
